import React from 'react';
import './App.css';

class App extends React.Component {
  state={
    data: null,
    loading: false
  }
  handleChange=e=>{
    console.log(this.fileUpload.files[0])
    this.setState({
      data: this.fileUpload.files[0]
    })
  }
  async send(){
    let self = this;
    await this.setState({
      loading: true
    })
    let formData = new FormData;
    formData.append('video', this.state.data);
    await fetch('http://dl.hadaf.academy/upload.php',{
      method:'POST',
      body: formData
    }).then(function(result){
      console.log(result);
    }).catch(function(error){
      console.log(error);
    }).finally(async function(final){
      await self.setState({loading: false})
    })
  }
  render(){
    return (
      <div className="App">
        {!this.state.loading &&
        <div>
          <input type="file" onChange={this.handleChange} ref={(ref) => this.fileUpload = ref}/>
          <button onClick={()=>this.send()}>
            send
          </button>
        </div>
        }
        {this.state.loading &&
          <span style={{fontSize:24}}>
            loading...
          </span>
        }
      </div>
    );
  }
}

export default App;
